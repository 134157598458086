
.follow-up {
  .select-section {
    padding: 70px 0;
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('../../images/followUp/bg.jpg') no-repeat top center;
      background-size: cover;
      opacity: 0.2;
      content: '';
      display: block;
    }
    .content {
      position: relative;
    }
    .form {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      .row {
        margin-bottom: 0 !important;
        align-items: center;
      } 
    }
  }
  .form-label {
    text-align: right;
  }
  .form-control {
    border-radius: 0;
  }
  .submit {
    background-color: #906b4f;
    border-color: #906b4f;
    padding: 5px 30px;
    border-radius: 3px;
    font-weight: bold;
    &:hover {
      background-color: #7b4115;
      border-color: #7b4115;
      
    }
  }
  .text-left {
    text-align: left;
  }
  .form-text {
    display: block;
    text-align: left;
    margin-top: 0.5rem;
  }
  .data-section {
    padding-top: 40px;
    padding-bottom: 60px;
    .responsive-table {
      padding-left: 0;
      li {
        border-radius: 3px;
        padding: 25px 0;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        border: 1px solid transparent;
      }
      .table-before-header {
        flex-direction: column;
        background-color: #906b4f;
        color: #fff;
        font-weight: bold;
        border-radius: 3px;
        padding: 30px;
        font-size: 18px;
        div {
          margin-bottom: 8px;
        }
      }
      .table-header {
        background-color: #dcab85;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        color: #fff;
        font-weight: bold;
      }
      .table-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
        &:hover {
          border: 1px solid #ffdea6;
          box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.2);
        }

        span{
          white-space: break-spaces;
        }

        .no-data{
          margin: auto;
        }
      }
      .col-1 {
        text-align: center;
        flex-basis: 15%;
      }
      .col-2 {
        flex-basis: 70%;
      }
      .col-3 {
        text-align: center;
        flex-basis: 15%;
      }
      
      .icon-link span {
        font-size: 24px;
        color: #906b4f;
        cursor: pointer;
        &:hover {
          color: #7b4115;
        }

        a{
          color: #906b4f;
          &:hover {
            color: #7b4115;
          }
        }
      }
      @media all and (max-width: 767px) {
        .table-header {
          display: none;
        }
        li {
          display: block;
        }
        .col {
          flex-basis: 100%;
          width: 100%;
        }
        .col-1 ,.col-3 {
          text-align: left;
        }
        
        .col {
          display: flex;
          padding: 10px;
          align-items: center;
          &:before {
            color: #dcab85;
            padding-right: 10px;
            content: attr(data-label);
            flex-basis: 30%;
            text-align: right;
            font-weight: bold;
          }
          span {
            flex-basis: 70%;
          }
        }
      }
    }
  }
}
.preview-album {
  height: 100%;
  .arrow {
    position: absolute;
    color: #fff;
    top: calc(50% - 15px);
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
    -webkit-filter: drop-shadow( 0px 2px 3px rgba(0, 0, 0, .7));
    filter: drop-shadow( 0px 2px 3px rgba(0, 0, 0, .7));
  }
  .arrow-left {
    left: 6px;
  }
  .arrow-right {
    right: 6px;
  }
  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border: 3px solid $color-light;
  }

  .carousel .slider-wrapper{
    height: 50vh;
    ul{
      height: 100%;
      li >div{
        height: 100%;
        img{
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .carousel .thumbs-wrapper{
    .thumbs{
      padding-left: 0;
      padding-right: 80px;
      height: 80px;

      .thumb{
        height: 100%;
        margin-right: unset;

        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .follow-up {
    .form-label {
      text-align: left;
    }
    .form-text {
      margin-top: 0;
      margin-bottom: 6px;
    }
    .submit {
      margin-top: 16px;
    }
  }
}