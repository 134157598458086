@import "variables";
@import "mixin";

///////

img {
  max-width: 100%;
}

.hide {
  display: none;
}

.home .Customized {
  background: #654D3F;
  border: 1px solid #654D3F;
}

.modal.warning,
.modal.success{
  top: 15vh;

  .modal-body{
    text-align: center;
    color: white;
    border-radius: 4px;
  }
}
.modal.warning{
  .modal-body{
    background: #d44848;
  }
}
.modal.success{
  .modal-body {
    background: #198754;
  }
}
.modal-backdrop.warning,
.modal-backdrop.success{
  display: none;
}

.align-center{
  align-items: center;
}

///////
.header {
  position: fixed;
  top: 0;
  width: 100vw;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  background: white;
  z-index: 10;
  border-bottom: 2px solid #E4A162;
  @include responsive($block: header);

  &.no_border{
    border-bottom: none;
  }

  .logo-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70vw;
    margin: auto;

    a{
      color: #391E0E;
      display: flex;
      align-items: center;
    }

    .logo-wrap {
      img {
        @include responsive($block: logo-image);
        transition: height .5s;
        object-fit: contain;
      }
    }

    span {
      margin-left: 10px;
      font-size: 28px;
      font-family: 'Noto Serif TC', serif;

      @media screen and (max-width: $break-point-md) {
        font-size: 20px;
      }
    }
  }

  .menu a {
    text-decoration: none;
    padding: 5px 10px;
    color: #654D3F;
  }

  .menu {
    @media screen and (max-width: $break-point-md) {
      position: fixed;
      top: calc((#{$header-height-sm} - 50px) / 2);
      width: 100%;
      font-size: 12px;
      height: 50px;
      z-index: -1;
    }

    .navbar {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      padding: unset;
    }

    .navbar-collapse {
      justify-content: center;
      @media screen and (max-width: $break-point-md) {
        background: rgba(255, 255, 255, 1);
      }
    }

    .navbar-light {
      .navbar-toggler {
        color: #654D3F;
        border: none;
        height: 100%;
        @media screen and (max-width: $break-point-md) {
          display: flex;
        }

        .navbar-toggler-icon {
          background-image: url("/images/hamburger.svg") !important;
        }
      }

      .navbar-toggler:focus {
        box-shadow: none;
      }

      .navbar-collapse {
        overflow: hidden;
        @media screen and (max-width: $break-point-md) {
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          clip-path: polygon(0 0, 100% 0, 120% 120%, 0% 120%);
        }

        .navbar-nav{
          &:hover{
            .nav-item:not(:hover):not(.active) {
              .nav-link {
                color: #BABABA;
              }
            }
          }
          &:not(.active){
            .nav-link.gray {
              color: #BABABA;
            }
          }

          .nav-item{
            height: 60px;
            line-height: 60px;
            overflow: hidden;
            position: relative;
            width: 105px;
            display: flex;
            justify-content: center;
            @media screen and (max-width: $break-point-md) {
              width: 100%;
            }
            &:hover {
              .nav-link.show {
                top: -50px;
                & ~ .hidden{
                  opacity: 1;
                  top: 0;
                }
              }
            }
            .nav-link{
              top: 0;
              color: #654D3F;
              position: absolute;
              z-index: 9;
              transition: .3s;
              font-size: 16px;
            }
            .nav-link.show {
              & ~ .hidden{
                opacity: 0;
                top: 40px;
                z-index: 1;
                transition: .3s;
              }
            }
            .nav-link.active {
              opacity: 0;
              & ~ .hidden{
                z-index: 1;
                opacity: 1;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}

.pageContainer {
  @include responsive($block: common-pageContainer);

  .content {
    height: 100%;
  }
}

.footer {
  height: $footerHeight;
  width: 95vw;
  text-align: center;
  font-size: 12px;
  padding-top: 20px;
  background: white;
  margin: auto;
}

.box-tip {
  text-align: center;
  word-wrap: break-word;
  background: white;
  z-index: 20;
  transition: .5s;
  @include responsive($block: box-tip);

  >span{
    position: relative;
    background: white;
    z-index: 9;
    display: block;
    border: 1px solid #391E0E;
    overflow-y: scroll;
    max-height: 90vh;
    height: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    ul{
      text-align: left;
      list-style: none;
      padding: 0 10px 0 0;

      li{
        span{
          margin-right: 5px;
          color: #f38d40;
          font-weight: bold;
          font-size: 18px;
        }
        svg{
          margin-right: 5px;
          color: #f38d40;
        }
      }
    }

    .box-tip-header{
      position: sticky;
      top: 0;
      width: 100%;
      height: 50px;
      background: white;
      z-index: 1;
      @media all and (max-width: $break-point-md) {
        background: #F9F5F2;
      }

      .close{
        width: 50px;
        height: 50px;
        line-height: 50px;
        color: #391E0E;
        font-size: 20px;
        cursor: pointer;
        text-align: center;
        margin-left: auto;
        @media all and (max-width: $break-point-md) {
          font-size: 24px;
        }
      }
    }
    .box-tip-content{
      margin: 0 30px;
    }
  }
}

.fe-modal.full-screen{
  .modal-dialog{
    margin: 0;

    .modal-body{
      padding: 0;
    }
  }
}
.fe-modal {
  .modal-header {
    border-bottom: 0;
  }
  .submit-section {
    text-align: center;
    margin-top: 20px;
  }
  .submit {
    background-color: #906b4f;
    border-color: #906b4f;
    border-radius: 3px;
    font-weight: bold;
    min-width: 120px;
    &:hover {
      background-color: #7b4115;
      border-color: #7b4115;
    }
  }
}

.service-modal{
  .modal-header {
    align-items: baseline;

    .tip-title {
      text-align: left;
      font-weight: bold;
      p {
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
      }
    }
  }

  .modal-body{
    font-size: 16px;
    @media all and (max-width: $break-point-md) {
      font-size: 14px;
    }
  }

  ul{
    text-align: left;
    list-style: none;
    padding: 0 10px 0 0;

    li{
      span{
        margin-right: 5px;
        color: #f38d40;
        font-weight: bold;
        font-size: 18px;
      }
      svg{
        margin-right: 5px;
        color: #f38d40;
      }
      p{
        margin-left: 1rem;
      }
    }
  }
}


 //light-box 作品集單張大圖燈箱
#SRLLightbox{

  .SRLCaptionContainer{
    min-height: 0;
    padding: 0;
  }
}