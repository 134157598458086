@import "~bootstrap/scss/bootstrap";
@import "scss/utils/_variables";
@import "scss/utils/mixin";
@import "scss/utils/common";
@import "scss/pages/home";
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@300&display=swap');
@import "scss/pages/service";
@import "scss/pages/contactUs";
@import "scss/pages/followUp";
@import "scss/pages/aboutUs";
@import "scss/pages/projects";
@import "scss/pages/pictures";
@import "scss/pages/admin/admin-layout.scss";
@import "scss/pages/admin/common.scss";
@import "scss/pages/admin/image.scss";

body {
  margin: 0;
  font-family: 'Microsoft JhengHei';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
