@import "../utils/variables";
@import "../utils/mixin";

.home {
  .pageContainer {
    @include responsive($block: home-pageContainer);
    @media screen and (max-width: $break-point-md) {
      height: auto;
    }
  }

  .container {
    padding: 0;
    height: 100%;
    position: relative;
    overflow: hidden;
    max-width: unset;
    @include responsive($block: container);  /* Banner 比例調整 */
    @media all and (max-width: $break-point-md) {
      padding: 0;
      max-width: unset;
    }

    .carousel {
      height: 100%;
      @media all and (max-width: $break-point-md) {
        height: 80vw;
      }

      .carousel-inner {
        height: 100%;

        .carousel-item.active, .carousel-item-next, .carousel-item-prev {
          height: 100%;

          .w-100 {
            height: 100%;
            @include responsive($block: w-100);  /* Banner 比例調整 */
          }
        }
      }

      .carousel-control-prev, .carousel-control-next, .carousel-indicators {
        display: none;
      }
    }

    .news-wrap {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      @include css3($property: 'background', $value: linear-gradient(180deg, rgba(255,255,255,0) 10%, rgba(0,0,0,0.3) 100%));
      @media all and (max-width: $break-point-md) {
        display: none;
      }

      .news-title {
        margin: auto;
        width: 100px;
        text-align: center;
        color: white;
        font-size: 20px;
        position: relative;
        margin-bottom: 30px;
        font-weight: bold;
        visibility: hidden;
       
        &.click-abled{
          cursor: pointer;
          position: relative;
          z-index: 1;
          text-shadow: 0 0px 3px #391E0E;
          visibility: visible;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 30px;
          width: 2px;
          background: white;
          bottom: -30px;
          left: 50px;
        }

        .arrow {
          &:before {
            content: '';
            display: block;
            position: absolute;
            height: 50px;
            width: 2px;
            background: white;
            top: -50px;
            left: 50px;
            animation-duration: 2s;
          }

          div {
            position: absolute;
            height: 2px;
            border-radius: 2px;
            width: 20px;
            background: white;
            top: -9px;
          }

          .left {
            transform: rotate(45deg);
            left: 34px;
          }

          .right {
            transform: rotate(-45deg);
            right: 32px;
          }
        }
      }
    }

    .box-tip-wrap {
      color: white;
      top: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      scroll-behavior: smooth;
      @include responsive($block: box-tip-wrap);

      &::-webkit-scrollbar {
        display: none;
      }

      @media all and (max-width: $break-point-md) {
        display: none;
      }

      .box-tip {
        font-size: 14px;
        @include responsive($block: news-box-tip);

        .tip-title {
          text-align: left;
          font-size: 28px;
          font-weight: bold;
          color: #391E0E;
        }

        > span {
          @include responsive($block: news-box-tip-span);
          color: black;

          li {
            margin: 10px 0;

            .title {
              cursor: pointer;
              margin: 0;
              position: relative;
              display: inline-block;
              font-size: 18px;

              &:before {
                transition: width .5s;
                content: '';
                position: absolute;
                width: 0;
                bottom: 2px;
                left: -5px;
                height: 6px;
                background: transparent;
                z-index: -1;
              }
              &.active:before {
                transition: .5s;
                content: '';
                position: absolute;
                width: 105%;
                bottom: 2px;
                left: -5px;
                height: 6px;
                background: #FFA15A;
                z-index: -1;
              }
            }
            
            .news-content {
              color: #654D3F;
              margin: 10px -20px 10px 98px;
              font-size: 15px;
              transition: .5s;
              line-height: 2em;
              white-space: break-spaces;

              .news-image{
                display: block;
              }
            }

            p.hidden {
              max-height: 0;
              margin: 10px -20px 10px 92px;
              opacity: 0;
              overflow: hidden;
            }
            p.show {
              opacity: 1;
            }
          }
        }

        .mask {
          display: none;
        }
      }
    }

    .news-rwd {
      display: none;
      @media all and (max-width: $break-point-md) {
        display: block;
        padding: 20px 5vw;
        font-size: 12px;
        li {
          list-style: none;
          .title {
            font-size: 15px;
          }
        }
      }


      .tip-title {
        font-size: 18px;
        font-weight: bold;
        color: #391E0E;
        text-align: center;
      }

      .box-tip {
        > span {
          background: #F9F5F2;
          text-align: left;
          font-size: 14px;
          line-height: 2em;
          color: #654D3F;

          b {
            font-size: 18px;
            font-weight: bold;
            color: #654D3F;
          }
        }

        .mask {
          background: transparent;
        }
      }
    }
  }
}
