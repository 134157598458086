@import "../utils/_variables";
@import "../utils/mixin";

.service {
  section {
    padding: 50px 50px;
    @media all and (max-width: $break-point-md) {
      padding: 30px 5px;
    }

    .title {
      font-size: 32px;
      display: block;
      text-align: center;
      margin: 0 auto 50px;
      font-weight: bold;
      color: #654D3F;

      @media all and (max-width: $break-point-md) {
        margin: 0 auto 30px;
      }
    }
  }

  section.rundown {
    .grid {
      display: grid;
      grid-template-columns: auto 50px auto 50px auto;
      grid-template-rows: auto auto auto auto;
      grid-auto-flow: row;
      //height: 500px;
      //width: 940px;
      margin: 0 auto;
      row-gap: 20px;
      @media all and (min-width: $break-point-md) {
        > .box:nth-of-type(6n) {
          display: none;
        }
      }
      @media all and (max-width: $break-point-md) {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
        row-gap: 0;
        width: 60vw;
      }
      @media all and (max-width: $break-point-sm) {
        width: 90%;
      }

      .box:not(.icon-right) {
        width: 100%;
        height: 100%;
        padding: 10px;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        background: white;

        b {
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          @media all and (max-width: $break-point-md) {
            justify-content: flex-start;
          }

          span {
            display: inline-block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #f38d40;
            line-height: 30px;
            margin-right: 5px;
            color: white;
          }
        }

        p {
          margin: 0;
        }

        .tip {
          font-size: 14px;
          font-weight: bold;
          color: #e4a162;
          margin: 10px 0;
          cursor: pointer;

          &:hover {
            color: #f38d40;
          }

          @media all and (max-width: $break-point-md) {
            font-size: 14px;
          }

          svg {
            margin-right: 5px;
          }
        }
      }

      .box.icon-right {
        color: #906b4f;
        font-size: 42px;
        text-align: center;
        margin: auto;
        @media all and (max-width: $break-point-md) {
          transform: rotate(90deg);
        }
      }
    }
  }

  section.data-section {
    position: relative;
    &:before{
      content: '';
      background: #fff3ea;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX( -50%);
      height: 100%;
      width: 100vw;
      z-index: -1;
    }

    .responsive-table {
      padding-left: 0;
      @media all and (min-width: $break-point-sm) {
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
      }

      li {
        border-radius: 3px;
        padding: 25px 0;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        @media all and (min-width: $break-point-sm) {
          border-radius: unset;
          margin-bottom: unset;
        }
      }

      .table-before-header {
        flex-direction: column;
        background-color: #906b4f;
        color: #fff;
        font-weight: bold;
        border-radius: 3px;
        padding: 30px;

        div {
          margin-bottom: 8px;
        }
      }

      .table-header {
        background-color: #dcab85;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        color: #fff;
        font-weight: bold;
        border-radius: 5px 5px 0 0;

        span {
          font-size: 14px;
        }
      }

      .table-row, .table-row2 {
        background-color: #ffffff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        @media all and (min-width: 767px) {
          box-shadow: none;
        }
      }

      .table-row.last {
        border-radius: 0 0 5px 5px;
      }

      .head {
        letter-spacing: 0.03em;
        width: 90%;
        margin: auto;
        flex-direction: column;
        background-color: #b1805e;
        color: #fff;
        font-weight: bold;
        border-radius: 3px;
        padding: 10px;

        span {
          font-size: 14px;
        }
      }

      .col > span > span {
        font-size: 14px;
        @media all and (max-width: $break-point-sm) {
          font-size: 14px;
        }
      }

      .col-1 {
        text-align: right;
        flex-basis: 15%;
      }

      .col-2 {
        flex-basis: 25%;
      }

      .col-3 {
        //text-align: center;
        flex-basis: 20%;
      }

      .col-4 {
        //text-align: center;
        flex-basis: 25%;
      }

      .icon-link span {
        font-size: 24px;
        color: #dcab85;
        cursor: pointer;

        &:hover {
          color: #4494f7;
        }
      }
      .engineer-charge {
        padding: 10px;
        text-align: center;
      }

      @media all and (min-width: $break-point-sm) {
        .table-row2 {
          display: none;
        }
      }
      @media all and (max-width: $break-point-sm) {
        .table-header, .table-row {
          display: none;
        }
        //.table-row2{
          li.table-row2 {
            display: block;
            padding: 0;

            .head{
              width: 100%;
              border-radius: 4px 4px 0 0;
            }
          }
        //}
        
        .col {
          flex-basis: 100%;
          width: 100%;
        }
        .col-1, .col-3 {
          text-align: left;
        }

        .col {
          display: flex;
          padding: 10px;
          align-items: center;

          &:before {
            color: #dcab85;
            padding-right: 10px;
            content: attr(data-label);
            flex-basis: 30%;
            text-align: right;
            font-weight: bold;
          }

          span {
            flex-basis: 70%;
          }
        }
      }
    }
  }
}