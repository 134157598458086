
// break-point
$break-point-lg: 1400px;
$break-point-md: 1200px;
$break-point-sm: 767px;

// logo-img-height
$img-height-lg: 48px;
$img-height-md: 44px;
$img-height-sm: 40px;

// header、footer、menu height
$footerHeight: 50px;
$menu-height: 60px;
$header-padding: calc(24px + 10px);
$header-padding-sm: calc(10px + 10px);
$header-height-bg: calc(#{$img-height-lg} + #{$menu-height} + #{$header-padding});
$header-height-md: calc(#{$img-height-md} + #{$menu-height} + #{$header-padding});
$header-height-sm: calc(#{$img-height-sm} + #{$header-padding-sm});

// colors
$color-primary: #CC7634;
$color-active: #E4A162;
$color-bg: #fff3ea;
$color-light: #f38d40;
