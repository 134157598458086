
.pictures{

  .pageContainer{

    .content{
      padding: 4vh 70px;

      .breadcrumb{
        justify-content: center;
        font-weight: bold;

        li{
          color: #654D3F;
          a{
            color: #654D3F;
          }
          a:hover{
            text-decoration: underline;
          }
        }
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        padding: 0 4px;
        max-width: 1920px;
        margin: auto;

        /* Create two equal columns that sits next to each other */
        .column {
          flex: 1;
          padding: 0 4px;
          @media all and (max-width: 767px) {
            flex: 100%;
          }
        }

        .column img {
          margin-top: 8px;
          vertical-align: middle;
          width: 100%;
        }
      }
    }

  }


}