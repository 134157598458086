@import "variables";

@mixin styling($size, $headerHeight, $block, $img-height) {
  /* Banner 比例調整 */
  $carousel-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
  $carousel-ratio: 1920/1262;

  @if $block == container {
    max-width: calc(calc(100vh - #{$headerHeight} - #{$footerHeight}) * #{$carousel-ratio});
  }
  @if $block == w-100 {
    object-fit: cover;
  }

  /* Header & pageContainter */
  @if $block == header {
    @if ($size == lg) or ($size == md) {
      padding: 24px 0 10px 0;
    } @else {
      padding: 10px 0;
    }
  } @else if $block == common-pageContainer {
    @if ($size == lg) or ($size == md) {
      min-height: $carousel-height;
      margin-top: $headerHeight;
    } @else {
      min-height: $carousel-height;
      margin-top: $headerHeight;
    }
  } @else if $block == home-pageContainer {
    @if ($size == lg) or ($size == md) {
      height: $carousel-height;
      margin-top: $headerHeight;
    } @else {
      min-height: $carousel-height;
      margin-top: $headerHeight;
    }
  }

  /* logo */
  @if $block == logo-image {
    height: $img-height;
  }

  /* 最新消息 RWD setting */
  @if $block == box-tip {
    @if ($size == sm) {
      position: fixed;
      top: 15vh;
      height: calc(100vh - 15vh - 12vh);
      width: 80vw;
      left: 10vw;
      font-size: 12px;
    }
  }

  /* 最新消息 */
  $height-ratio: 0.85;
  $width-ratio: 0.8;

  @if $block == box-tip-wrap {
    height: calc(100vh - #{$headerHeight});
  } @else if $block == news-box-tip {
    margin: calc(100vh - #{$headerHeight}) auto calc(calc(#{$carousel-height} * calc(1 - #{$height-ratio}) / 2) + #{$footerHeight});
    width: calc(calc(100vh - #{$headerHeight} - #{$footerHeight}) * (#{$carousel-ratio} * #{$width-ratio}));
  } @else if $block == news-box-tip-span {
    height: calc(#{$carousel-height} * #{$height-ratio});
  }

  /* 作品集分類 */
  @if $block == filter-section {
    top: $headerHeight;
  }
}

@mixin responsive($block) {
  @media all and (min-width: $break-point-lg) {
    @include styling($size: lg, $headerHeight: $header-height-bg, $block: $block, $img-height: $img-height-lg);
  }
  @media all and (min-width: $break-point-md) and (max-width: $break-point-lg) {
    @include styling($size: md, $headerHeight: $header-height-md, $block: $block, $img-height: $img-height-md);
  }
  @media all and (max-width: $break-point-md) {
    @include styling($size: sm, $headerHeight: $header-height-sm, $block: $block, $img-height: $img-height-sm);
  }
}

/* 瀏覽器前綴 */
@mixin css3($property, $value) {
  @each $prefix in -webkit-,
  -moz-,
  -ms-,
  -o-,
  '' {
    #{$prefix}#{$property}: $value;
  }
}