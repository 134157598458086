
.about-us {
  .img-group {
    display: flex;
  }
  .img {
    max-width: 100%;
    border: 3px solid #fff;
  }
  .img-line {
    position: relative;
  }
  .img-line:before {
    content: '';
    width: 6px;
    height: 100%;
    left: 60%;
    top: 0;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    display: block;
  }
  .img-line:after {
    content: '';
    width: 6px;
    height: 100%;
    right: 20%;
    top: 0;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    display: block;
  }
  .img-line1:before {
    left: 20%;
  }
  .img-line2:before {
    left: 45%;
  }
  .img-line2:after {
    left: 65%;
  }
  .img-line3:before {
    left: 50%;
  }
  .section {
    padding: 50px 0;
    text-align: center;
    &:nth-child(odd) {
      background: $color-bg;
    }
    &.about {
      padding: 60px 0 120px;
    }
    .title {
      font-size: 32px;
      display: inline-block;
      margin: 0 auto 20px;
      font-weight: bold;
      color: #654D3F;
      position: relative;
      & + div {
        max-width: 700px;
        margin: 0 auto;
        padding: 0 10px;
      }
    }
    .circle {
      position: relative;
      display: inline-block;
      margin: 0 10px;
      &:before {
        content: '';
        display: block;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #ffd3b2;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: -20px;
      }
      span {
        font-size: 60px;
        color: #ffa059;
        position: relative;
      }
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .item {
        padding: 20px 30px;
        .icon {
          width: 80px;
          svg {
            max-width: 100%;
          }
        }
        .info {
          margin: 20px 0;
          font-weight: bold;
          color: #654D3F;
        }
      }
    }
    .area {
      flex-direction: row-reverse;
      justify-content: center;
      .area-info {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
      }
      .icon-tw {
        max-width: 250px;
        padding: 20px 0;
        .st1 {
            fill: #ffd5b6;
        }
    
        .st3 {
            fill: #ffa059;
        }
    
        .st4 {
            fill: #fd720a;
        }
      }
    }
  }
  .section.idea .info p {
    position: relative;
    margin-bottom: 4rem;
    &:after {
      content: '…';
      display: inline-block;
      transform: rotate(90deg);
      font-size: 36px;
      color: #ffd6d6;
      position: absolute;
      left: 50%;
      top: 100%;
    }
  }
}
// @media only screen and (max-width: 576px) {
//   .about-us {
//   }
// }