
.projects {
  .filter-section {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    position: sticky;
    z-index: 9;
    background: white;
    @include responsive($block: filter-section);

    .label {
      padding: 6px 24px;
      font-weight: bold;
      cursor: pointer;
      margin: 0 8px;
      color: #391E0E;
      &:hover {
        background-color: #ffeace;
      }
      &.active {
        background-color: #CC7634;
        color: #fff;
      }
    }
  }
  .album-section {
    display: flex;
    justify-content: center;
  }
  .album {
    margin: 10px 0;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    position: relative;
    color: #391E0E;
    &::before {
      transition: all .3s;
      content: attr(data-name);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: rgba(255, 255, 255, 0.8);
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    &:hover {
      border-color: #E4A062;
    }
    &:hover::before {
      opacity: 1;
    }
  }
  .album-name {
    display: none;
    text-align: center;
    padding: 8px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #E4A062;
    color: #391E0E;
    font-weight: 700;
  }
}

@media only screen and (max-width: 1020px) {
  .projects {
    .album-name {
      display: block;
    }
  }
}