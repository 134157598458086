
.contact-us {
  .information {
    position: relative;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  .pageContainer {
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(../../images/contactUs/bg.jpg) center center no-repeat;
      background-size: cover;
      //opacity: 0.5;
      content: '';
      display: block;
      height: 80vh;
      min-height: 700px;
      max-height: 800px;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //background: url(../../images/contactUs/bg.png) center center no-repeat;
      background-size: cover;
      opacity: 0.5;
      content: '';
      display: block;
      height: 80vh;
      background-color: #522c00;
      min-height: 700px;
      max-height: 800px;
    }
  }

  .content {
    position: relative;
    border: none;

    & > .row {
      align-items: center;
    }

    .form {
      background-color: #fff;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      padding: 30px 40px;
      max-width: 650px;
      margin: 20px auto;
      font-size: 14px;

      .required:before {
        content: '*';
        color: red;
        display: inline-block;
        margin-right: 4px;
      }

      .form-select, .input-group-text {
        border-radius: 0;
      }

      .form-control.date{
        font-size: 14px;
      }

      .form-control.textarea{
        height: 100px;
      }

      .form-control.error:not(.budget){
        border: 1px solid #d44848;
      }

      .form-control.budget.error {
        border-top: 1px solid #d44848;
        border-left: 1px solid #d44848;
        border-bottom: 1px solid #d44848;
      }

      .form-control.budget.error + .input-group-text {
        border-top: 1px solid #d44848;
        border-right: 1px solid #d44848;
        border-bottom: 1px solid #d44848;
      }

      div.error {
        color: #d44848;
        font-size: 12px;
        padding-top: 6px;
      }

      .catch-wrap{
        img{
          cursor: pointer;
        }
        span{
          color: #654D3F;
          font-size: 12px;
        }
      }
    }

    .form-label {
      text-align: right;
    }

    .textarea {
      text-align: left;
    }

    .input-group {
      .form-control:not(.is-valid) {
        border-right: none;
      }

      .input-group-text {
        background-color: transparent;
      }
    }

    .submit {
      background-color: $color-light;
      border-color: $color-light;
      padding: 5px 50px;
      border-radius: 0;
      margin: 10px 0;
    }
  }

  .form-control {
    border-radius: 0;
  }

  .bottom-content {
    background-color: $color-bg;
    padding: 30px 0;
  }

  .company-info {
    .info-text {
      display: flex;
      align-items: center;
    }

    ul {
      list-style: none;
      text-align: left;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      padding: 30px;
      margin: 0 auto;
      font-size: 20px;
      font-weight: bold;
      border: 1px solid #fff;
      border-radius: 4px;
      width: 100%;
      max-width: 650px;
    }

    li {
      display: inline-flex;
      padding: 10px 0;

      &:hover {
        .fb-icon,
        .envelope-icon,
        .phone-icon,
        .map-icon {
          background-color: $color-light;

          path {
            fill: #fff3ea;
          }
        }

        .link-text {
          color: #ffb781;
        }
      }

      path {
        fill: $color-light;
      }

      .link {
        display: flex;
        align-items: center;
      }

      .link-text {
        display: inline-block;
        margin-left: 16px;
      }
    }

    iframe {
      width: 100%;
      height: 25vw;
      max-height: 320px;
    }
  }
}

.fb-icon,
.envelope-icon,
.phone-icon,
.map-icon {
  width: 60px;
  height: 60px;
  display: inline-block;
  background-color: #fff3ea;
  padding: 11px 12px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
}

a {
  text-decoration: none;
  color: #fff;

  &:hover {
    color: #000;
  }
}

@media all and (max-width: 1400px) {
  .contact-us .information {
    font-size: 16px;
  }
}

@media all and (max-width: 1200px) {
  .contact-us {
    .pageContainer {
      .background {
        min-width: initial;
      }
      &:before {
        min-width: initial;
      }
    }
    .company-info {
      ul {
        border-color: #ffcfab;
        font-size: 16px;
      }
  
      li .link {
        color: #5a5a5a;
      }
    }
  }
  .fb-icon, .envelope-icon, .phone-icon, .map-icon {
    width: 40px;
    height: 40px;
    padding: 6px 8px;
    font-size: 18px;
  }
}

@media all and (max-width: 1200px) {
  .contact-us .content .form {
    margin-bottom: 100px;
  }
}

@media all and (max-width: 768px) {
  .contact-us .pageContainer  {
    .background {
      height: 85vh;
    }
    &:before {
      height: 85vh;
    }
  }

  .contact-us .company-info ul {
    padding: 15px 20px;
    box-shadow: 0 0 15px rgba(138, 138, 138, 0.15);
  }
}

@media all and (max-width: 576px) {
  .contact-us {
    .content {
      .form-label {
        text-align: left;
      }
    }
  }
}