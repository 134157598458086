.sidebar {
  height: 100%;
  width: 160px;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: #ccc;
  overflow-x: hidden;
  padding-top: 20px;

}

.sidebar > a {
  display: block;
  height: 50px;
  text-align: center;
  text-decoration: none;
  color: black;
}

.admin-container {
  position: absolute;
  top: 0;
  left: 160px;
  padding: 30px;
  width: calc(100% - 160px);
}
