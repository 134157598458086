.admin-container {
  .title {
    margin-bottom: 40px;
  }

  .custom-btn {
    margin-top: 32px;
  }

  .new-btn {
    float: right;
    margin-top: 10px;
  }

  .pagination {
    justify-content: center;
  }

  a.normal-link {
    color: #0d6efd;
    text-decoration: underline;
  }

  .custom-alert {
    position: fixed;
    right: 50px;
    bottom: 10px;
    height: 60px;
    z-index: 1056;
    font-size: 16px;
    font-weight: bold;
  }

  .spinner-border {
    margin-top: 5px;
    border-width: 3px;
  }

  .order-input {
    width: 100px;
  }
  
  td {
    white-space: break-spaces;
  }
}

.modal .required::before {
  content: '*' ;
  color: red;
  margin-right: 4px;
}
